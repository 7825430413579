<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Login Left-->
      <b-col
          xl="5"
          class="d-flex justify-content-center align-items-center flex-column auth-bg px-2 p-md-1 p-lg-3 p-xl-5 h-100"
      >
        <div class="auth-locale">
          <locale />
        </div>-
        <!-- Brand logo-->
        <b-link class="brand-logo-login">
          <div v-if="activeComponent !== backButton" class="back-icon"
               @click="activeComponent = backButton">
            <feather-icon
                icon="ArrowLeftIcon"
                size="2x"
                style="color: #000"
            />
          </div>
          <b-img :src="appLogoImage" width="200" />
        </b-link>
        <!-- /Brand logo-->

        <b-col
            xs="12"
            sm="10"
            md="6"
            lg="5"
            xl="12"
            class="px-xl-4 flex-12-none"
        >

          <KeepAlive>
            <component :is="activeComponent"
                       @updateActiveComponent="updateActiveComponent($event)"
                       @updateBackButton="updateBackButton($event)"
                       @updatePhone="updatePhone($event)"
                       @updateEmail="updateEmail($event)"
                       @updateUser="updateUser($event)"
                       :backButton="backButton"
                       :email="email"
                       :phone="phone"
                       :user="user"/>
          </KeepAlive>

          <!-- form -->
<!--          <validation-observer-->
<!--              ref="loginForm"-->
<!--              #default="{invalid}"-->
<!--          >-->
<!--            <b-form-->
<!--                class="auth-login-form mt-2"-->
<!--                @submit.prevent="login"-->
<!--            >-->
<!--              &lt;!&ndash; phone &ndash;&gt;-->
<!--              <b-form-group-->
<!--                  label="Cep telefonu"-->
<!--                  label-for="login-phone"-->
<!--              >-->
<!--                <validation-provider-->
<!--                    #default="{ errors }"-->
<!--                    name="phone"-->
<!--                    vid="phone"-->
<!--                    rules="required"-->
<!--                >-->
<!--                  <b-form-input-->
<!--                      id="login-phone"-->
<!--                      v-model="phone"-->
<!--                      :state="errors.length > 0 ? false:null"-->
<!--                      name="login-phone"-->
<!--                      placeholder="05xx"-->
<!--                  />-->
<!--                  <small class="text-danger">{{ errors[0] }}</small>-->
<!--                </validation-provider>-->
<!--              </b-form-group>-->

<!--              &lt;!&ndash; forgot password &ndash;&gt;-->
<!--              <b-form-group>-->
<!--                <div class="d-flex justify-content-between">-->
<!--                  <label for="login-password">-->
<!--                    <info-icon size="1x" v-b-tooltip.hover title="Tooltip directive content"-->
<!--                               class="mr-05 cursor-pointer"></info-icon>-->
<!--                    Pin Kodu-->
<!--                  </label>-->
<!--                  <b-link :to="{name:'auth-forgot-password'}">-->
<!--                    <small>Pin kodumu unuttum?</small>-->
<!--                  </b-link>-->
<!--                </div>-->
<!--                <validation-provider-->
<!--                    #default="{ errors }"-->
<!--                    name="Password"-->
<!--                    vid="password"-->
<!--                    rules="required"-->
<!--                >-->
<!--                  <b-input-group-->
<!--                      class="input-group-merge"-->
<!--                      :class="errors.length > 0 ? 'is-invalid':null"-->
<!--                  >-->
<!--                    <b-form-input-->
<!--                        id="login-password"-->
<!--                        v-model="password"-->
<!--                        :state="errors.length > 0 ? false:null"-->
<!--                        class="form-control-merge"-->
<!--                        :type="passwordFieldType"-->
<!--                        name="login-password"-->
<!--                        placeholder="Password"-->
<!--                    />-->
<!--                    <b-input-group-append is-text>-->
<!--                      <feather-icon-->
<!--                          class="cursor-pointer"-->
<!--                          :icon="passwordToggleIcon"-->
<!--                          @click="togglePasswordVisibility"-->
<!--                      />-->
<!--                    </b-input-group-append>-->
<!--                  </b-input-group>-->
<!--                  <small class="text-danger">{{ errors[0] }}</small>-->
<!--                </validation-provider>-->
<!--              </b-form-group>-->

<!--              &lt;!&ndash; submit buttons &ndash;&gt;-->
<!--              <b-button-->
<!--                  type="submit"-->
<!--                  variant="primary"-->
<!--                  block-->
<!--                  :disabled="loading || invalid"-->
<!--              >-->
<!--                <b-spinner v-if="loading" small></b-spinner>-->
<!--                Giriş-->
<!--              </b-button>-->
<!--            </b-form>-->
<!--          </validation-observer>-->

<!--          <b-card-text class="text-center mt-2">-->
<!--            <span>Boho Live’da yeni misin? </span>-->
<!--            <b-link :to="{name:'auth-register'}">-->
<!--              <span>&nbsp;Kayıt Ol</span>-->
<!--            </b-link>-->
<!--          </b-card-text>-->
        </b-col>
      </b-col>
      <!-- /Login Left-->

      <!-- Right Text-->
      <b-col
        xl="7"
        class="d-none d-xl-flex align-items-center login-right"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center">
          <b-img
            fluid
            :src="imgUrl"
            alt="Login V2"
          />
        </div>
      </b-col>
      <!-- /Right Text-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BRow, BCol, BLink, BFormGroup, BFormInput, BInputGroupAppend, BInputGroup, BCardText, BCardTitle, BImg, BForm,
  BButton, BSpinner, VBTooltip,
} from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import { required } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import store from '@/store/index'
import { InfoIcon } from 'vue-feather-icons'
import SocialButtons from '@/components/auth/SocialButtons'
import Phone from '@/components/auth/Phone'
import Email from '@/components/auth/Email'
import Password from '@/components/auth/Password'
import PersonalInformation from '@/components/auth/PersonalInformation'
import ContactInformation from '@/components/auth/ContactInformation'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { gtmTrackNewEvent } from '@/helpers/helpers'
import { $themeConfig } from '@themeConfig'
import Locale from "@core/layouts/components/app-navbar/components/Locale.vue";

const { appLogoImage } = $themeConfig.app

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    Locale,
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    BSpinner,

    InfoIcon,

    ContactInformation,
    Email,
    Password,
    PersonalInformation,
    Phone,
    SocialButtons,

    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      activeComponent: 'SocialButtons',
      appLogoImage: appLogoImage,
      backButton: 'SocialButtons',
      email: '',
      loading: false,
      password: '',
      phone: '',
      sideImg: require('@/assets/images/auth/login-bg.svg'),
      user: null,

      // validation rules
      required,
    }
  },
  watch: {
    activeComponent (val) {
      switch (val) {
        case 'Phone':
        case 'Email':
          this.backButton = 'SocialButtons'
          break;
      }
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  created() {
    localStorage.setItem('phone', null)
  },
  methods: {
    login() {
      this.$refs.loginForm.validate().then(success => {
        if (success) {
          this.loading = true
          useJwt.login({phoneNumber: this.phone, password: this.password,})
              .then(response => {
                const defaultAbility = [
                  {
                    action: 'manage',
                    subject: 'all',
                  },
                  {
                    action: 'read',
                    subject: 'all',
                  },
                ]
                useJwt.setToken(response.data.body.loginToken.token)
                this.$store.dispatch('auth/updateUser', {
                  ability: defaultAbility,
                  ...response.data.body
                })
                this.$ability.update(defaultAbility)

                gtmTrackNewEvent('login', {
                  category: 'Login Funnel',
                  action: 'Login',
                })

                this.$router.push('/')
              })
              .catch(() => {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: this.$t('You should check your login information and try again'),
                    variant: 'danger',
                  },
                })
              })
              .finally(() => {this.loading = false})
        }
      })
    },
    updateActiveComponent($event) {
      this.activeComponent = $event
    },
    updateBackButton($event) {
      this.backButton = $event
    },
    updateEmail($event) {
      this.email = $event
      localStorage.setItem('email', $event)
    },
    updatePhone($event) {
      this.phone = $event
      localStorage.setItem('phone', $event)
    },
    updateUser($event) {
      this.user = $event
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
