export const locales = [
	{
		locale: 'en',
		img: require('@/assets/images/flags2/en.png'),
		name: 'English',
	},
	{
		locale: 'tr',
		img: require('@/assets/images/flags2/TR.png'),
		name: 'Türkçe',
	},
	// {
	// 	locale: 'sa',
	// 	img: require('@/assets/images/flags2/sa.png'),
	// 	name: 'عربي',
	// },
]
