<template>
  <b-nav-item-dropdown
    id="dropdown-grouped"
    variant="link"
    class="dropdown-language"
    right
  >
    <template #button-content>
<!--      {{ currentLocale.locale.toUpperCase() }}-->
      <b-img
        :src="currentLocale.img"
        height="14px"
        width="22px"
        :alt="currentLocale.locale"
      />
    </template>
    <b-dropdown-item
      v-for="localeObj in locales"
      :key="localeObj.locale"
      @click="updateLocale(localeObj.locale)"
    >
<!--      {{ localeObj.locale.toUpperCase() }}-->
      <b-img
        :src="localeObj.img"
        height="14px"
        width="22px"
        :alt="localeObj.locale"
      />
      <span class="ml-50">{{ localeObj.name }}</span>
    </b-dropdown-item>
  </b-nav-item-dropdown>
</template>

<script>
import { BNavItemDropdown, BDropdownItem, BImg } from 'bootstrap-vue'
import { localeChanged } from 'vee-validate'
import { localize } from 'vee-validate'
import { locales } from '@/libs/i18n/all-locales'
import useJwt from '@/auth/jwt/useJwt'

export default {
  components: {
    BNavItemDropdown,
    BDropdownItem,
    BImg,
  },
  data() {
    return {
      locales
    }
  },
  created() {
    localize(this.$i18n.locale)
    localeChanged()

    if (this.$i18n.locale === 'sa') {
      localStorage.setItem('isRTL', 'on')
    }
    this.$store.commit('appConfig/TOGGLE_RTL', this.$i18n.locale === 'sa')
  },
  // ! Need to move this computed property to comp function once we get to Vue 3
  computed: {
    currentLocale() {
      const _locale = this.locales.find(l => l.locale === this.$i18n.locale)
      if (_locale && this.$store.getters['auth/isAuthenticated']) {
        this.updateLocaleFromServer(_locale.locale)
      }

      return _locale
    },
  },
  methods: {
    updateLocale (locale) {
      this.$i18n.locale = locale
      localize(locale)
      localeChanged()
      localStorage.setItem('locale', locale)

      if (locale === 'sa') {
        localStorage.setItem('isRTL', 'on')
      }
      this.$store.commit('appConfig/TOGGLE_RTL', locale === 'sa')

      this.updateLocaleFromServer(locale)
    },
    updateLocaleFromServer(lang) {
      if (lang) {
        useJwt.updateLanguage(lang)
      }
    }
  }
}
</script>

<style>

</style>
