<template>
  <div>
    <b-card-title class="slogan-text mb-1" title-tag="h2">
      {{ $t('auth.email.title') }}
    </b-card-title>
    <b-card-text class="slogan-sub-text mb-2" v-html="$t('auth.email.description')" />
    <!-- form -->
    <validation-observer
        ref="loginForm"
        #default="{invalid}">
      <b-form
          class="auth-login-form mt-2"
          @submit.prevent="emailCheck">
        <b-form-group
            label-for="login-phone"
        >
          <validation-provider
              #default="{ errors }"
              :name="$t('Email')"
              vid="email"
              rules="required"
              data-vv-validate-on="blur"
          >
            <b-form-input
                id="email"
                v-model="email"
                name="email"
                :state="errors.length > 0 ? false:null"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <b-button
            class="mt-2"
            type="submit"
            variant="primary"
            block
            :disabled="loading || invalid"
        >
          <b-spinner v-if="loading" small></b-spinner>
          {{ $t('Continue') }}
        </b-button>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { BForm, BFormGroup, BFormInput, BCardTitle, BCardText, BButton, BSpinner } from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import {gtmTrackNewEvent, throwDefaultError} from "@/helpers/helpers";

export default {
  components: {
    BButton,
    BCardText,
    BCardTitle,
    BFormGroup,
    BForm,
    BFormInput,
    BSpinner,

    ValidationProvider,
    ValidationObserver,
  },
  data () {
    return {
      email: '',
      loading: false,
    }
  },
  created () {

  },
  methods: {
    emailCheck () {
      this.$refs.loginForm.validate().then(success => {
        if (success) {
          this.loading = true
          this.$emit('updateEmail', this.email)
          useJwt.emailVerification({email: this.email, lang: this.$i18n.locale})
              .then(response => {
                this.$emit('updateActiveComponent', 'PersonalInformation')
                this.$emit('updateBackButton', 'Email')
                this.$store.dispatch('auth/setAuthType', 'email')
              })
              .catch(error => {
                if (error?.response?.data?.header?.error?.errorCode == '370') {
                  gtmTrackNewEvent('registration', {
                    category: 'Login Funnel',
                    action: 'Registration',
                  })
                  this.$emit('updateActiveComponent', 'Password')
                  this.$emit('updateBackButton', 'Email')
                  this.$store.dispatch('auth/setAuthType', 'email')
                } else {
                  this.loading = false
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: this.$t('You should check your login information and try again'),
                      variant: 'danger',
                    },
                  })
                }
              })
              .finally(() => {
                this.loading = false
              })
        }
      })
    },
  }
}
</script>

<style src="vue-tel-input/dist/vue-tel-input.css"></style>
