<template>
  <div>
    <!-- form -->
    <validation-observer
      ref="form"
      #default="{invalid}"
    >
      <b-form
        class="auth-login-form"
        @submit.prevent="loginOrRegister"
      >
        <!-- forgot password -->
        <b-form-group>
          <b-row class="password-information">
            <b-col sm="12">
              <h3>
                {{ $t('auth.password.information.title', {authType: authType === 'phone' ? 'SMS' : $t('Email')}) }}
              </h3>
              <p v-if="user">
                {{ $t('auth.password.information.description') }}
              </p>
              <p v-else>
                {{ $t('auth.password.information.descriptionCurrentUser') }}
              </p>
            </b-col>

            <b-col sm="12" class="mt-2">
              <div class="d-flex justify-content-center">
                <validation-provider
                  #default="{ errors }"
                  :name="$t('Password')"
                  vid="password"
                >
                  <PincodeInput
                    v-model="password"
                  />
                  <br>
                  <div class="text-danger text-center mt-2">{{ errors[0] }}</div>
                </validation-provider>
              </div>
            </b-col>

            <b-col v-if="(authType === 'email' && backButton !== 'PersonalInformation') || authType !== 'email'" sm="12">
              <p class="send-again">
                <span class="link" @click="sendAgain">
                  {{ $t('auth.password.Forgot Pin Code') }}
                </span>
              </p>
            </b-col>
          </b-row>
        </b-form-group>

        <!-- submit buttons -->
        <b-button
          class="mt-2"
          type="submit"
          variant="primary"
          block
          :disabled="loading || invalid"
        >
          <b-spinner v-if="loading" small />
          {{ $t('Continue') }}
        </b-button>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BFormGroup, BForm, BButton, BSpinner, BCol, BRow,
} from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
// eslint-disable-next-line import/extensions
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import PincodeInput from 'vue-pincode-input'
import {gtmPushUserId, gtmTrackNewEvent, showNotify, throwDefaultError} from '@/helpers/helpers'

const defaultAbility = [
  {
    action: 'manage',
    subject: 'all',
  },
  {
    action: 'read',
    subject: 'all',
  },
]

export default {
  components: {
    BCol,
    BForm,
    BButton,
    BFormGroup,
    BRow,
    BSpinner,

    PincodeInput,

    ValidationObserver,
    ValidationProvider,
  },
  mixins: [togglePasswordVisibility],
  props: {
    backButton: {
      type: String,
      default: '',
      required: false,
    },
    email: {
      type: String,
      default: '',
      required: false,
    },
    phone: {
      type: String,
      default: '',
      required: false,
    },
  },
  data() {
    return {
      loading: false,
      password: '',
    }
  },
  computed: {
    authType() {
      return this.$store.getters['auth/authType']
    },
    user() {
      return this.$store.getters['auth/tempUser2']
    },
  },
  watch: {
    password(newValue, oldValue) {
      if (oldValue.length === 3 && newValue.length === 4) {
        this.loginOrRegister()
      }
    },
  },
  methods: {
    letIn(response) {
      useJwt.setToken(response.data.body.loginToken.token)
      this.$store.dispatch('auth/setToken', response.data.body.loginToken.token)
      this.$store.dispatch('auth/updateUser', {
        ability: defaultAbility,
        ...response.data.body,
      })
      gtmPushUserId(response.data.body.id)
      gtmTrackNewEvent('login', {
        category: 'Login Funnel',
        action: 'Login',
      })
      this.$ability.update(defaultAbility)
      if (localStorage.getItem('loginRedirect')) {
        this.$router.push({name: localStorage.getItem('loginRedirect')})
        localStorage.removeItem('loginRedirect')
      } else {
        this.$router.push('/')
      }
    },
    loginOrRegister() {
      if (this.$store.getters['auth/tempUser2']) {
        this.register()
      } else {
        this.login()
      }
    },
    login() {
      if (this.password.length < 4) {
        this.$refs.form.setErrors({
          password: this.$t('{field} is required', { field: this.$t('Password') }),
        })
        return false
      } else {
        // Email mi telefon mu
        if (this.authType === 'email') {
          if (this.email && this.email !== '') {
            this.loading = true
            useJwt.emailLogin({ email: this.email, pinCode: this.password, lang: this.$i18n.locale })
                .then(response => {
                  this.letIn(response)
                })
                .catch(error => {
                  throwDefaultError(this.$toast, error)
                  // this.$toast({
                  //   component: ToastificationContent,
                  //   props: {
                  //     title: 'Giriş bilgilerini kontrol edip tekrar denemelisin',
                  //     variant: 'danger',
                  //   },
                  // })
                })
                .finally(() => {
                  this.loading = false
                })
          }
        } else {
          if (this.phone && this.phone !== '') {
            this.loading = true
            useJwt.login({phoneNumber: this.phone, password: this.password, lang: this.$i18n.locale})
                .then(response => {
                  this.letIn(response)
                })
                .catch(error => {
                  throwDefaultError(this.$toast, error)
                  // this.$toast({
                  //   component: ToastificationContent,
                  //   props: {
                  //     title: 'Giriş bilgilerini kontrol edip tekrar denemelisin',
                  //     variant: 'danger',
                  //   },
                  // })
                })
                .finally(() => {
                  this.loading = false
                })
          }
        }
      }
    },
    register() {
      if (this.password.length < 4) {
        this.$refs.form.setErrors({
          password: this.$t('{field} is required', { field: this.$t('Password') }),
        })
        return false
      } else {
        this.loading = true
        if (this.authType === 'email') {
          useJwt.emailRegister({ email: this.email, pinCode: this.password, lang: this.$i18n.locale })
              .then(async (response) => {
                gtag('event', 'conversion', {'send_to': 'AW-805828675/lOrECPO8oYIBEMPwn4AD', 'user': response.data.body});

                useJwt.setToken(response.data.body.loginToken.token)
                await this.$store.dispatch('auth/setToken', response.data.body.loginToken.token)
                await this.$store.dispatch('auth/updateUser', {
                  ability: defaultAbility,
                  ...response.data.body
                })

                const data = {
                  userName: this.user.userName,
                }

                const profile = {
                  gender: this.user.gender,
                  country: this.user.country,
                  lookingForGender: this.user.lookingForGender,
                  userName: this.user.userName,
                  date: this.user.profile.date,
                  month: this.user.profile.month.value,
                  year: this.user.profile.year,
                }

                this.loading = true

                useJwt.putPendingUser(profile)
                    .then(response2 => {
                      this.$ability.update(defaultAbility)
                      if (localStorage.getItem('loginRedirect')) {
                        this.$router.push({name: localStorage.getItem('loginRedirect')})
                        localStorage.removeItem('loginRedirect')
                      } else {
                        this.$router.push('/')
                      }
                    })
                    .catch(error => {
                      throwDefaultError(this.$toast, error)
                      this.loading = false
                    })

                // const _updateMe = useJwt.updateMe(data)
                // const _updateProfile = useJwt.updateProfile(profile)
                //
                // Promise.all([_updateMe, _updateProfile])
                //     .then(values => {
                //       this.$ability.update(defaultAbility)
                //       this.$router.push('/')
                //     })
                //     .catch(error => {
                //       throwDefaultError(this.$toast, error)
                //     })
                //     .finally(() => { this.loading = false })
              })
              .catch(() => {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: this.$t('You should check your login information and try again'),
                    variant: 'danger',
                  },
                })
              })
        } else {
          const userData = {
            /// ...this.user,
            country: this.user.country,
            gender: this.user.gender,
            lookingForGender: this.user.lookingForGender,
            userName: this.user.userName,
            phoneNumber: this.phone,
            password: this.password,
            date: this.user.profile.date,
            month: this.user.profile.month.value,
            year: this.user.profile.year,
            lang: this.$i18n.locale,
          }
          useJwt.register(userData)
              .then(response => {
                gtag('event', 'conversion', {'send_to': 'AW-805828675/lOrECPO8oYIBEMPwn4AD', 'user': response.data.body});

                useJwt.setToken(response.data.body.loginToken.token)
                this.$store.dispatch('auth/setToken', response.data.body.loginToken.token)
                this.$store.dispatch('auth/updateUser', {
                  ability: defaultAbility,
                  ...response.data.body,
                })
                this.$ability.update(defaultAbility)
                if (localStorage.getItem('loginRedirect')) {
                  this.$router.push(localStorage.getItem('loginRedirect'))
                  localStorage.removeItem('loginRedirect')
                } else {
                  this.$router.push('/')
                }
              })
              .catch(() => {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: this.$t('You should check your login information and try again'),
                    variant: 'danger',
                  },
                })
              })
              .finally(() => {
                this.loading = false
              })
        }
      }
    },
    sendAgain() {
      this.loading = true
      // Email mi telefon mu
      if (this.authType === 'email') {
        useJwt.forgotPasswordEmail({ email: this.email, lang: this.$i18n.locale })
            .then(() => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: this.$t('Password sent to email address.'),
                  variant: 'success',
                },
              })
            })
            .catch(error => {
              throwDefaultError(this.$toast, error)
            })
            .finally(() => {
              this.loading = false
            })
      } else {
        useJwt.forgotPassword(this.phone, this.$i18n.locale)
            .then(() => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: this.$t('Password sent to phone number.'),
                  variant: 'success',
                },
              })
            })
            .catch(error => {
              throwDefaultError(this.$toast, error)
            })
            .finally(() => {
              this.loading = false
            })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.password-information {
  font-family: 'Lexend', sans-serif;
  h3 {
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 28px;
    text-align: center;
    color: #000000;
  }

  p {
    font-family: inherit;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 25px;
    text-align: center;
    color: #8893A4;
  }

  .send-again {
    margin-top: 14px;
    font-family: inherit;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 25px;
    text-align: center;
    color: #8893A4;

    .link {
      cursor: pointer;
      color: #F9447A;
    }
  }
}
</style>

<style lang="scss">
div.vue-pincode-input-wrapper {
  .vue-pincode-input {
    margin: 0 8px;
    padding: 5px;
    text-align: center;
    border: none;
    box-shadow: 0 0 2px rgb(0 0 0 / 50%);
    border-radius: 12px;
    font-family: 'Lexend', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 28px;
    line-height: 35px;
    color: #000000;

    &:focus {
      box-shadow: none;
      border: 2px solid #000;
    }
  }
}
</style>
