<template>
  <div>
    <b-card-title class="slogan-text mb-1" title-tag="h2">
      {{ $t('auth.phone.title') }}
    </b-card-title>
    <b-card-text class="slogan-sub-text mb-2" v-html="$t('auth.phone.description')" />
    <!-- form -->
    <validation-observer
        ref="loginForm"
        #default="{invalid}">
      <b-form
          class="auth-login-form mt-2"
          @submit.prevent="phoneCheck">
        <b-form-group
            label-for="login-phone"
        >
          <validation-provider
              #default="{ errors }"
              :name="$t('Mobile phone number')"
              vid="phone"
              rules="required"
          >
            <vue-tel-input
                v-model="phone"
                @open="onDropdownOpen(true)"
                @close="onDropdownOpen(false)"
                @country-changed="countryChanged"
                mode="auto"
                @validate="phoneValidate"
                :inputOptions="{placeholder: $t('Mobile phone number')}"
                validCharactersOnly
                :styleClasses="['phone-input']"
            >
              <template v-slot:arrow-icon>
                <span class="font-small-3 mr-05">
                  {{ country ? '+' + country.dialCode : '' }}
                </span>
                <font-awesome-icon v-if="open" icon="fa-solid fa-chevron-up"/>
                <font-awesome-icon v-else icon="fa-solid fa-chevron-down"/>
              </template>
            </vue-tel-input>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <b-button
            class="mt-2"
            type="submit"
            variant="primary"
            block
            :disabled="loading || invalid"
        >
          <b-spinner v-if="loading" small></b-spinner>
          {{ $t('Continue') }}
        </b-button>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { BForm, BFormGroup, BFormInput, BCardTitle, BCardText, BButton, BSpinner } from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import {gtmTrackNewEvent, throwDefaultError} from "@/helpers/helpers";

export default {
  components: {
    BButton,
    BCardText,
    BCardTitle,
    BFormGroup,
    BForm,
    BFormInput,
    BSpinner,

    ValidationProvider,
    ValidationObserver,
  },
  data () {
    return {
      country: null,
      loading: false,
      open: false,
      phone: '',
      valid: false,
    }
  },
  created () {

  },
  computed: {
    phoneWithCountryCode () {
      return this.country ? '+' + this.country.dialCode + this.phone.replace(/ /g, "") : this.phone.replace(/ /g, "")
    }
  },
  methods: {
    countryChanged (country) {
      this.country = country
    },
    onDropdownOpen (open) {
      this.open = open
    },
    phoneCheck () {
      if (!this.valid) {
        this.$refs.loginForm.setErrors({
          phone: this.$t('Check phone number'),
        })
        return false
      }
      this.$refs.loginForm.validate().then(success => {
        if (success) {
          this.loading = true
          this.$emit('updatePhone', this.phoneWithCountryCode)
          console.log('this.$i18n.locale', this.$i18n.locale)
          useJwt.phoneCheck(this.phoneWithCountryCode, this.$i18n.locale)
              .then(response => {
                this.loading = false
                this.$emit('updateActiveComponent', 'Password')
                this.$emit('updateBackButton', 'Phone')
                this.$store.dispatch('auth/setAuthType', 'phone')
              })
              .catch(error => {
                if (error?.response?.data?.header?.error?.errorCode == '305') {
                  gtmTrackNewEvent('registration', {
                    category: 'Login Funnel',
                    action: 'Registration',
                  })
                  useJwt.phoneVerification(this.phoneWithCountryCode, this.$i18n.locale)
                      .then(response => {
                        this.$emit('updateActiveComponent', 'PersonalInformation')
                        this.$emit('updateBackButton', 'Phone')
                      })
                      .catch(error => {
                        throwDefaultError(this.$toast, error)
                        // this.$toast({
                        //   component: ToastificationContent,
                        //   props: {
                        //     title: 'Kod gönderilirken beklenmedik bir hata oldu. Lütfen daha sonra tekrar deneyin.',
                        //     variant: 'danger',
                        //   },
                        // })
                      })
                      .finally(() => {
                        this.loading = false
                      })
                } else {
                  this.loading = false
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: this.$t('You should check your login information and try again'),
                      variant: 'danger',
                    },
                  })
                }
              })
        }
      })
    },
    phoneValidate(status) {
      this.valid = status ? status.valid : false
      this.$refs.loginForm.reset()
    },
    phoneVerification() {
      if (this.user.phoneNumber !== '') {

      }
    },
  }
}
</script>

<style src="vue-tel-input/dist/vue-tel-input.css"></style>
