<template>
  <div>
    <validation-observer
        ref="personalInformationForm"
        #default="{invalid}"
    >
      <b-form
          class="auth-login-form"
          @submit.prevent="formSubmitted"
      >
        <b-row>
          <b-col md="12">
            <b-form-group
                :label="$t('Gender')"
                label-for="gender"
            >
              <validation-provider
                  #default="{ errors }"
                  :name="$t('Gender')"
                  vid="gender"
                  rules="required"
              >
                <div class="demo-inline-spacing">
                  <b-form-radio
                      v-model="user.gender"
                      name="gender"
                      value="male"
                  >
                    {{ $t('Male') }}
                  </b-form-radio>
                  <b-form-radio
                      v-model="user.gender"
                      name="gender"
                      value="female"
                  >
                    {{ $t('Female') }}
                  </b-form-radio>
                </div>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group
                :label="$t('Interested gender')"
                label-for="lookingForGender"
            >
              <validation-provider
                  #default="{ errors }"
                  :name="$t('Interested gender')"
                  vid="lookingForGender"
                  rules="required"
              >
                <div class="demo-inline-spacing">
                  <b-form-radio
                      v-model="user.lookingForGender"
                      name="lookingForGender"
                      value="male"
                  >
                    {{ $t('Male') }}
                  </b-form-radio>
                  <b-form-radio
                      v-model="user.lookingForGender"
                      name="lookingForGender"
                      value="female"
                  >
                    {{ $t('Female') }}
                  </b-form-radio>
                  <b-form-radio
                      v-model="user.lookingForGender"
                      name="lookingForGender"
                      value="both"
                  >
                    {{ $t('Both') }}
                  </b-form-radio>
                </div>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group
                :label="$t('Username')"
                label-for="userName"
            >
              <validation-provider
                  #default="{ errors }"
                  :name="$t('Username')"
                  vid="userName"
                  rules="required"
              >
                <b-spinner v-if="userNameChecking" style="position: absolute; right: 8px" small class="m-1"
                           type="grow"/>
                <b-form-input
                    id="userName"
                    v-model="user.userName"
                    :placeholder="$t('Username')"
                    @focusout="checkUsername"
                    :disabled="userNameChecking"
                    :state="errors.length > 0 ? false : (userNameAvailable || null)"
                />
                <small class="text-danger">{{ errors[0] }}</small>
                <small v-if="userNameAvailable" class="text-success">
                  {{ $t('Username is available') }}
                </small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group
                :label="$t('Birthdate')"
                label-for="birth-date"
            ></b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group
                :label="$t('Day')"
                label-for="date"
            >
              <validation-provider
                  #default="{ errors }"
                  :name="$t('Day')"
                  vid="date"
                  rules="required"
              >
                <v-select
                    id="date"
                    v-model="user.profile.date"
                    :clearable="false"
                    :options="dates"
                    :selectable="option => ! option.includes($t('Day'))"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group
                :label="$t('Month')"
                label-for="month"
            >
              <validation-provider
                  #default="{ errors }"
                  :name="$t('Month')"
                  vid="month"
                  rules="required"
              >
                <v-select
                    id="month"
                    v-model="user.profile.month"
                    :clearable="false"
                    :options="months"
                    :selectable="option => !option.value.includes($t('Month'))"
                    label="text"
                    :item-value="item => item.value"
                    :return-object="false"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group
                :label="$t('Year')"
                label-for="year"
            >
              <validation-provider
                  #default="{ errors }"
                  :name="$t('Year')"
                  vid="year"
                  rules="required"
              >
                <v-select
                    id="year"
                    v-model="user.profile.year"
                    :clearable="false"
                    :options="years"
                    :selectable="option => ! option.includes($t('Year'))"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col v-if="false" md="12">
            <validation-provider
                #default="{ errors }"
                :name="$t('Living country')"
                vid="country"
                rules="required"
            >
              <b-form-group
                  :label="$t('Living country')"
                  label-for="country"
                  :state="errors.length > 0 ? false:null"
              >
                <v-select
                    id="country"
                    v-model="user.country"
                    :clearable="false"
                    :filter="countrySearch"
                    :reduce="country => country.code"
                    :options="countries"
                >
                  <template v-slot:option="option">
                    <span class="mr-1">
                      {{ option.emoji }}
                    </span>
                    {{ $t(option.name) }}
                  </template>
                  <template v-slot:selected-option="option">
                    <span class="mr-1">
                      {{ option.emoji }}
                    </span>
                    {{ $t(option.name) }}
                  </template>
                </v-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
<!--          <b-col md="12">-->
<!--            <validation-provider-->
<!--                #default="{ errors }"-->
<!--                name="İlgi alanları"-->
<!--                vid="hobbies"-->
<!--                rules="required"-->
<!--            >-->
<!--              <b-form-group-->
<!--                  label="İlgi alanlarını seç"-->
<!--                  label-for="hobbies"-->
<!--                  :state="errors.length > 0 ? false:null"-->
<!--              >-->
<!--                <v-select-->
<!--                    id="hobbies"-->
<!--                    multiple-->
<!--                    v-model="user.hobbies"-->
<!--                    :clearable="false"-->
<!--                    item-value="code"-->
<!--                    :get-option-label="(option) => option.name"-->
<!--                    :options="hobbies"-->
<!--                />-->
<!--                <small class="text-danger">{{ errors[0] }}</small>-->
<!--              </b-form-group>-->
<!--            </validation-provider>-->
<!--          </b-col>-->
          <b-col md="12">
            <b-button
                class="mt-2"
                type="submit"
                variant="primary"
                block
                :disabled="loading || invalid || !userNameAvailable"
            >
              <b-spinner v-if="loading" small></b-spinner>
              {{ $t('Continue') }}
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import { required, email } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BRow, BCol, BLink, BButton, BForm, BFormGroup, BFormInput, BInputGroup, BInputGroupAppend, BImg,
  BCardTitle, BCardText, BFormRadio, BFormInvalidFeedback, BSpinner, BInputGroupPrepend
} from 'bootstrap-vue'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import store from '@/store/index'
import useJwt from '@/auth/jwt/useJwt'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import vSelect from 'vue-select'
import cities from '@/data/cities.json'
import countries from '@/data/countries.json'
import {showNotify, throwDefaultError} from "@/helpers/helpers";
import _ from "lodash";

export default {
  props: {
    phone: {
      type: String,
      default: '',
      required: false
    }
  },
  components: {
    BRow,
    BImg,
    BCol,
    BLink,
    BButton,
    BForm,
    BFormInvalidFeedback,
    BFormRadio,
    BCardText,
    BCardTitle,
    BFormGroup,
    BFormInput,
    BInputGroupPrepend,
    BInputGroup,
    BInputGroupAppend,
    BSpinner,
    vSelect,
    // validations
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      cities: cities,
      countries: _.sortBy(countries, 'name').map(c => ({ ...c, name: this.$t(c.name) })),
      dates: [
        this.$t('Day'), '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19',
        '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31',
      ],
      hobbies: [],
      loading: false,
      loadingHobbies: false,
      months: [
        { text: this.$t('Month'), value: this.$t('Month') },
        { text: this.$t('January'), value: '1' },
        { text: this.$t('February'), value: '2' },
        { text: this.$t('March'), value: '3' },
        { text: this.$t('April'), value: '4' },
        { text: this.$t('May'), value: '5' },
        { text: this.$t('June'), value: '6' },
        { text: this.$t('July'), value: '7' },
        { text: this.$t('August'), value: '8' },
        { text: this.$t('September'), value: '9' },
        { text: this.$t('October'), value: '10' },
        { text: this.$t('November'), value: '11' },
        { text: this.$t('December'), value: '12' },
      ],
      sideImg: require('@/assets/images/pages/register-v2.svg'),
      user: {
        gender: 'male',
        lookingForGender: 'female',
        userName: '',
        phoneNumber: '',
        profile: {
          date: '',
          month: '',
          year: '',
        },
        hobbies: [],
      },
      userNameAvailable: false,
      userNameChanged: false,
      userNameChecking: false,
      userNameCheckingDelay: 1000,
      userNameCheckingTimeout: null,
      userPhoneValidating: false,
      userPhoneVerificationSent: false,
    }
  },
  watch: {
    'user.userName' (val) {
      if (val && val !== '' && !this.userNameChanged) {
        clearTimeout(this.userNameCheckingTimeout)
        this.userNameCheckingTimeout = setTimeout(() => {
          this.checkUsername()
        }, this.userNameCheckingDelay)
      } else {
        this.userNameAvailable = false
      }
    },
  },
  created() {
    // this.getHobbies()
    this.$emit('updateUser', null)
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    socialRegistering() {
      return this.$store.getters['auth/socialRegistering']
    },
    tempUser(){
      return this.$store.getters['auth/tempUser']
    },
    years() {
      const _years = [this.$t('Year')]
      const lastYear = new Date().getFullYear() - 18

      for (let i = 0; i < 100; i++) {
        _years.push((lastYear - i).toString())
      }

      return _years
    },
  },
  methods: {
    checkUsername() {
      if (this.user.userName !== '') {
        this.userNameChecking = true
        this.userNameChanged = true
        useJwt.checkUsername({ username: this.user.userName, lang: this.$i18n.locale })
            .then(response => {
              this.userNameAvailable = true
            })
            .catch(error => {
              this.userNameAvailable = false
              this.$refs.personalInformationForm.setErrors({
                userName: this.$t('You cannot use this username'),
              })
            })
            .finally(() => {
              this.userNameChanged = false
              this.userNameChecking = false
            })
      }
    },
    countrySearch(options, search) {
      return options.filter(country => {
        return country.name.toLowerCase().includes(search.toLowerCase())
            || country.code.toLowerCase().includes(search.toLowerCase())
      })
    },
    formSubmitted() {
      if (this.userNameAvailable) {
        if (this.socialRegistering) {
          // Profil bilgilerini güncelle
          const data = {
            userName: this.user.userName,
          }
          const profile = {
            gender: this.user.gender,
            date: this.user.profile.date,
            month: this.user.profile.month.value,
            year: this.user.profile.year,
          }
          const searchCriteria = {
            gender: this.user.lookingForGender,
          }

          this.loading = true

          const token = this.tempUser.loginToken.token

          const _updateMe = useJwt.updateMe(data, token)
          const _updateProfile = useJwt.updateProfile(profile, token)
          const _updateSearchCriteria= useJwt.updateSearchCriteria(searchCriteria, token)
          // const _updateMyInterests = useJwt.updateMyInterests(this.user.hobbies.map(i => i.code), token)

          Promise.all([_updateMe, _updateProfile, _updateSearchCriteria])
              .then(values => {
                // useJwt.updateMyInterests(this.user.hobbies.map(i => i.code), token)
                useJwt.getMe(token)
                    .then(response => {
                      const user = response.data.body
                      this.$store.dispatch('auth/setGoogleUser', null)
                      this.$store.dispatch('auth/setSocialRegistering', false)
                      const defaultAbility = [
                        {
                          action: 'manage',
                          subject: 'all',
                        },
                        {
                          action: 'read',
                          subject: 'all',
                        },
                      ]
                      useJwt.setToken(token)
                      this.$store.dispatch('auth/setToken', token)
                      this.$store.dispatch('auth/updateUser', {
                        ability: defaultAbility,
                        ...user
                      })
                      this.$ability.update(defaultAbility)
                      this.$router.push('/')
                    })
                    .catch(error => {
                      throwDefaultError(this.$toast, error)
                    })
                    .finally(() => { this.loading = false })
              })
              .catch(error => {
                throwDefaultError(this.$toast, error)
              })
              .finally(() => { this.loading = false })
        } else {
          this.$store.dispatch('auth/updateTempUser2', this.user)
          this.$emit('updateActiveComponent', 'Password')
          this.$emit('updateBackButton', 'PersonalInformation')
        }
      }
    },
    getHobbies() {
      this.loadingHobbies = true
      setTimeout(() => {
        this.hobbies = [
          {
            "code": 1,
            "name": "Spor"
          },
          {
            "code": 2,
            "name": "Müzik"
          },
          {
            "code": 3,
            "name": "Dans"
          }
        ]
        this.loadingHobbies = false
      }, 500)
      // TODO: backenddeki hata giderildikten sonra açılacak
      // useJwt.getInterests()
      //     .then(response => {
      //       this.hobbies = response.data.body
      //     })
      //     .catch(error => {
      //       this.$toast({
      //         component: ToastificationContent,
      //         props: {
      //           title: 'Hobiler yüklenirken beklenmedik bir hata oldu',
      //           variant: 'danger',
      //         },
      //       })
      //     })
      //     .finally(() => this.loadingHobbies = false)
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
@import '@core/scss/vue/libs/vue-wizard.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>
