<template>
  <div>
    <b-overlay :show="loading" no-center variant="transparent" rounded="sm">
      <template #overlay>
        <loading-spinner/>
      </template>
      <b-card-title class="text-center slogan-text mb-5" title-tag="h2" v-html="$t('auth.socials.title')" />
      <div class="social-buttons">
        <div v-if="false" class="facebook-login-button social-button"
             :class="{'disable-button': !socialCheck}"
             @click="socialCheck ? loginWithFacebook() : showAcceptTerms()">
          <b-img :src="iconFacebook" class="mr-05"/>
          {{ $t('auth.socials.Connect via Facebook') }}
        </div>
        <div class="google-login-button social-button"
             :class="{'disable-button': !enableGoogleAuth || !socialCheck}"
             @click="enableGoogleAuth && socialCheck ? loginWithGoogle() : showAcceptTerms()">
          <b-img :src="iconGoogle" class="mr-05"/>
          {{ $t('auth.socials.Connect via Google') }}
        </div>
        <div class="apple-login-button social-button"
             :class="{'disable-button': !socialCheck}"
             @click="socialCheck ? loginWithApple() : showAcceptTerms()">
          <b-img :src="iconApple" class="mr-05"/>
          {{ $t('auth.socials.Connect via Apple') }}
        </div>
        <div class="email-login-button social-button"
             :class="{'disable-button': !socialCheck}"
             @click="socialCheck ? triggerEmits([{event: 'updateActiveComponent', args: 'Email'}, {event: 'updateBackButton', args: 'SocialButtons'}]) : showAcceptTerms()">
          <b-img :src="iconEmail" class="mr-05" width="28"/>
          {{ $t('auth.socials.Connect via Email') }}
        </div>
        <div class="phone-login-button social-button"
             v-if="(!currentLocale) || (currentLocale && currentLocale.locale === 'tr')"
             :class="{'disable-button': !socialCheck}"
             @click="socialCheck ? triggerEmits([{event: 'updateActiveComponent', args: 'Phone'}, {event: 'updateBackButton', args: 'SocialButtons'}]) : showAcceptTerms()">
          <b-img :src="iconPhone" class="mr-05"/>
          {{ $t('auth.socials.Connect via Phone') }}
        </div>
        <router-link :to="{name: 'public-users'}" class="visitor-button social-button">
          {{ $t('Continue as a visitor') }}
        </router-link>
        <div class="social-terms-check" :class="{'term-did-not-accepted': !socialCheck && termDidNotAccepted}">
          <input type="checkbox" id="socialCheck" v-model="socialCheck" class="mr-1">
          <label for="socialCheck" v-html="$t('socialCheckText')" />
        </div>
        <div v-if="!socialCheck && termDidNotAccepted" class="fs-14" style="color: red;">
          {{ $t('Please accept the membership agreement.') }}
        </div>
      </div>
    </b-overlay>
  </div>
</template>

<script>
import { initFbsdk } from '@/config/oAuth/facebook_oAuth.js'
import { BImg, BCardTitle, BOverlay } from 'bootstrap-vue'
import {showNotify, throwDefaultError} from '@/helpers/helpers'
import useJwt from '@/auth/jwt/useJwt'
import { locales } from '@/libs/i18n/all-locales'

const appUrl = process.env.VUE_APP_URL
const appleClientId = process.env.VUE_APP_APPLE_CLIENT_ID || 'com.arasana.web'

export default {
  components: {
    BCardTitle,
    BImg,
    BOverlay
  },
  data () {
    return {
      enableGoogleAuth: false,
      enableGoogleAuthInterval: null,
      enableGoogleAuthIntervalTime: 100,
      iconApple: require('@/assets/images/icons/apple.svg'),
      iconEmail: require('@/assets/images/icons/email.svg'),
      iconGoogle: require('@/assets/images/icons/google.svg'),
      iconFacebook: require('@/assets/images/icons/facebook.svg'),
      iconPhone: require('@/assets/images/icons/phone.svg'),
      locales,
      loading: false,
      socialCheck: false,
      termDidNotAccepted: false,
    }
  },
  computed: {
    currentLocale() {
      return this.locales.find(l => l.locale === this.$i18n.locale)
    },
  },
  mounted() {
    initFbsdk()
  },
  created() {
    AppleID.auth.init({
      clientId : appleClientId,
      scope : 'name email',
      redirectURI : appUrl + '/login',
      state : new Date().getTime().toString(),
      usePopup : true
    });

    this.enableGoogleAuthInterval = setInterval(() => {
      if (this.$gAuth.isLoaded()) {
        clearInterval(this.enableGoogleAuthInterval)
        this.enableGoogleAuth = true
      }
    }, this.enableGoogleAuthIntervalTime)
  },
  methods: {
    async loginWithApple() {
      try {
        const appleUserData = await AppleID.auth.signIn()

        // Handle successful response.
        if (typeof appleUserData.authorization.id_token === 'string') {
          const userInfo = {
            code: appleUserData.authorization.code,
            lang: this.$i18n.locale,
          }

          this.loading = true

          useJwt
              .loginWithApple(userInfo)
              .then(response => {
                const data = response.data.body
                if (data.registrationStatus === 'pending') {
                  this.$store.dispatch('auth/setAppleUser', data)
                  this.$store.dispatch('auth/setTempUser', data)
                  this.$store.dispatch('auth/setSocialRegistering', true)
                  this.$emit('updateActiveComponent', 'PersonalInformation')
                  this.$emit('updateBackButton', 'SocialButtons')
                } else {
                  this.$store.dispatch('auth/setAppleUser', null)
                  this.$store.dispatch('auth/setSocialRegistering', false)
                  const defaultAbility = [
                    {
                      action: 'manage',
                      subject: 'all',
                    },
                    {
                      action: 'read',
                      subject: 'all',
                    },
                  ]
                  useJwt.setToken(data.loginToken.token)
                  this.$store.dispatch('auth/setToken', data.loginToken.token)
                  this.$store.dispatch('auth/updateUser', {
                    ability: defaultAbility,
                    ...data
                  })
                  this.$ability.update(defaultAbility)
                  if (localStorage.getItem('loginRedirect')) {
                    this.$router.push({name: localStorage.getItem('loginRedirect')})
                    localStorage.removeItem('loginRedirect')
                  } else {
                    this.$router.push('/')
                  }
                }
              })
              .catch(error => {
                throwDefaultError(this.$toast, error)
              })
              .finally(() => {
                this.loading = false
              })
        } else {
          // Email adresi yok uyarı göster
          showNotify(this.$toast, this.$t('Please give permission to share your email address and try again'), 'warning')
        }
      } catch ( error ) {
        throwDefaultError(this.$toast, error)
      }
    },
    loginWithGoogle () {
      this.loading = true
      this.$gAuth
          .signIn()
          .then(GoogleUser => {
            const userInfo = {
              accessToken: GoogleUser.getAuthResponse().id_token,
              userId: GoogleUser.getId(),
              lang: this.$i18n.locale,
            }

            useJwt
              .loginWithGoogle(userInfo)
              .then(response => {
                const data = response.data.body
                if (data.registrationStatus === 'pending') {
                  this.$store.dispatch('auth/setGoogleUser', data)
                  this.$store.dispatch('auth/setTempUser', data)
                  this.$store.dispatch('auth/setSocialRegistering', true)
                  this.$emit('updateActiveComponent', 'PersonalInformation')
                  this.$emit('updateBackButton', 'SocialButtons')
                } else {
                  this.$store.dispatch('auth/setGoogleUser', null)
                  this.$store.dispatch('auth/setSocialRegistering', false)
                  const defaultAbility = [
                    {
                      action: 'manage',
                      subject: 'all',
                    },
                    {
                      action: 'read',
                      subject: 'all',
                    },
                  ]
                  useJwt.setToken(data.loginToken.token)
                  this.$store.dispatch('auth/setToken', data.loginToken.token)
                  this.$store.dispatch('auth/updateUser', {
                    ability: defaultAbility,
                    ...data
                  })
                  this.$ability.update(defaultAbility)
                  if (localStorage.getItem('loginRedirect')) {
                    this.$router.push({name: localStorage.getItem('loginRedirect')})
                    localStorage.removeItem('loginRedirect')
                  } else {
                    this.$router.push('/')
                  }
                }
              })
              .catch(error => {
                throwDefaultError(this.$toast, error)
              })
              .finally(() => {
                this.loading = false
              })
          })
          .catch(error => {
            this.loading = false
            throwDefaultError(this.$toast, error)
          })
    },
    loginWithFacebook () {
      window.FB.login(response => {
        if (response && response.authResponse) {
          const userInfo = {
            accessToken: response.authResponse.accessToken,
            userId: response.authResponse.userID,
            lang: this.$i18n.locale,
          }

          useJwt
              .loginWithFacebook(userInfo)
              .then(response => {
                const data = response.data.body
                if (data.registrationStatus === 'pending') {
                  this.$store.dispatch('auth/setFacebookUser', data)
                  this.$store.dispatch('auth/setTempUser', data)
                  this.$store.dispatch('auth/setSocialRegistering', true)
                  this.$emit('updateActiveComponent', 'PersonalInformation')
                  this.$emit('updateBackButton', 'SocialButtons')
                } else {
                  this.$store.dispatch('auth/setFacebookUser', null)
                  this.$store.dispatch('auth/setSocialRegistering', false)
                  const defaultAbility = [
                    {
                      action: 'manage',
                      subject: 'all',
                    },
                    {
                      action: 'read',
                      subject: 'all',
                    },
                  ]
                  useJwt.setToken(data.loginToken.token)
                  this.$store.dispatch('auth/setToken', data.loginToken.token)
                  this.$store.dispatch('auth/updateUser', {
                    ability: defaultAbility,
                    ...data
                  })
                  this.$ability.update(defaultAbility)
                  if (localStorage.getItem('loginRedirect')) {
                    this.$router.push({name: localStorage.getItem('loginRedirect')})
                    localStorage.removeItem('loginRedirect')
                  } else {
                    this.$router.push('/')
                  }
                }
              })
              .catch(error => {
                throwDefaultError(this.$toast, error)
              })
              .finally(() => {
                this.loading = false
              })
        }
      }, { scope: 'public_profile,email' })
    },
    showAcceptTerms() {
      this.termDidNotAccepted = true
      // showNotify(this.$toast, this.$t('Please accept the membership agreement.'), 'info')
    },
    triggerEmits(emits) {
      emits.forEach(emit => {
        this.$emit(emit.event, emit.args)
      })
    }
  }
}
</script>

<style >

</style>
